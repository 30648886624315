// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "y_vp d_gv d_cs";
export var heroHeaderCenter = "y_gw d_gw d_cs d_dw";
export var heroHeaderRight = "y_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "y_vq d_gr d_cw";
export var heroParagraphCenter = "y_gs d_gs d_cw d_dw";
export var heroParagraphRight = "y_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "y_vr d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "y_vs d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "y_vt d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "y_vv d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "y_vw d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "y_tZ x_tZ";
export var heroExceptionNormal = "y_t0 x_t0";
export var heroExceptionLarge = "y_t1 x_t1";
export var Title1Small = "y_tB x_tB x_s5 x_s6";
export var Title1Normal = "y_tC x_tC x_s5 x_s7";
export var Title1Large = "y_tD x_tD x_s5 x_s8";
export var BodySmall = "y_tQ x_tQ x_s5 x_tp";
export var BodyNormal = "y_tR x_tR x_s5 x_tq";
export var BodyLarge = "y_tS x_tS x_s5 x_tr";